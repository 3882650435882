<template>
  <div>

    <Form
      :form="form"
      purpose="edit"
      :route="`2-lab-reports/${$route.params.id}`"
    />

  </div>
</template>

<script>


import Form from '@/component/lab-reports-2/Form.vue'
import Card from '@/view/content/Card.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      // Form
      form: {
        date: '',
        patient_id: '',
        doctor_id: '',
        lab_template_id: '',
        report: '',
        lab_category_id: '',
        attachment: [],
        display_attachment: [],
        removed_attachment: []
      },
    }
  },

  methods: {

    async get() {
      this.form = await module.get(`lab-reports/${this.$route.params.id}`)            
      // If Data Not found
      if (this.form == null) {
        // Redirect To List
        this.$router.push('lab-report/list')
      } else {
        this.form['_method'] = "put"
        this.form.display_attachment = this.form.attachment        
        this.form.attachment = []
        this.form.removed_attachment = []
      }
    }

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pemeriksaan Lab" },
      { title: "Edit Laporan" },
    ])
    // Get Data
    this.get()
  },

}
</script>
